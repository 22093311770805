import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ImageEditorComponent } from '@ih/image';

@Component({
  selector: 'ih-product-image-editor',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, ReactiveFormsModule, ImageEditorComponent],
  templateUrl: './product-image-editor.component.html',
  styleUrls: ['./product-image-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductImageEditorComponent {
  data = inject(MAT_DIALOG_DATA);

  image = new FormControl(this.data);
}
