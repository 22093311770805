<ih-basic-dialog [scrollable]="true">
  <ng-container ih-header>Product editor</ng-container>
  <ng-container ih-content>
    <form id="productForm" [formGroup]="productForm" (ngSubmit)="save()">
      <div class="product-sidebar">
        <div class="product-image-container" hidden>
          <ih-image
            *ngIf="imageInfo$ | async as imageInfo"
            class="product-image"
            [imageInfo]="imageInfo"
            alt="product image"
          ></ih-image>
          <button
            mat-icon-button
            type="button"
            class="btn btn-file fileinput-new btn-product-image"
            (click)="showChangeImage()"
          >
            <mat-icon>
              <svg id="camera" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z"
                />
              </svg>
            </mat-icon>
          </button>
        </div>
        <!-- <a
          class="view-product-link"
          *ngIf="productForm.value.productId"
          mat-stroked-button
          mat-dialog-close
          [routerLink]="['/', 'products', productForm.value.productId]"
          >View</a
        > -->
      </div>
      <div class="product-editor-container fx-layout-column">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Product name</mat-label>
          <input cdkFocusInitial matInput placeholder="Add a name for your product" formControlName="name" />
          <mat-error *ngIf="productForm.controls['name'].hasError('required')"> Product name is required </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Product description</mat-label>
          <textarea
            #descriptionInput
            matInput
            cdkTextareaAutosize
            placeholder="Add a description for your product"
            formControlName="description"
          ></textarea>
          <mat-hint class="fx-layout-align-end" *ngIf="descriptionInput.value.length > 1000"
            >{{ descriptionInput.value.length || 0 }}/2000</mat-hint
          >
          <mat-error *ngIf="productForm.controls['description'].hasError('maxlength')">
            Product description cannot be longer than 2000 characters
          </mat-error>
        </mat-form-field>
        <!-- <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>Quantity</mat-label>
        <input matInput placeholder="0" formControlName="quantity" type="number" />
        <mat-error *ngIf="productForm.controls['quantity'].hasError('required')">
          Product quantity is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Limit</mat-label>
        <input id="limitInput" matInput placeholder="0" formControlName="limit" type="number" />
      </mat-form-field> -->
        <div class="price-list-header fx-layout-row fx-layout-align-start-center">
          <h3 class="fx-flex">Prices</h3>
          <button mat-icon-button type="button" (click)="addPrice()">
            <mat-icon svgIcon="plus"></mat-icon>
          </button>
        </div>
        <ih-info-panel type="note" [dismissable]="false" [showIcon]="false">
          <span title>Prices cannot be edited after saving</span>
          <span>Once prices are saved they cannot be edited or removed, only archived</span>
        </ih-info-panel>
        @if (prices.length === 0 && archivedPrices.length === 0) {
          <div class="price-list-empty">No prices added</div>
        } @else {
          @for (price of prices; track $index) {
            <mat-list role="list">
              <mat-list-item class="price-list-item" role="listitem" [formGroup]="price" (click)="editItem($index)">
                <div class="price-info-container fx-layout-row fx-layout-align-start-center">
                  @if (price.value.productPriceId || editingIndex() !== $index) {
                    <div class="price-info fx-flex">
                      <ih-price-interval
                        [amount]="price.value.amount ?? 0"
                        [currency]="price.value.currency?.code"
                        [interval]="price.value.interval ?? RecurringInterval.Never"
                        [intervalCount]="price.value.intervalCount"
                        [taxBehavior]="price.value.taxBehavior!"
                      ></ih-price-interval>
                    </div>
                  } @else {
                    <div class="price-editor-wrapper fx-flex">
                      <ih-price-editor [parentForm]="price"></ih-price-editor>
                      <div class="price-action-row fx-layout-row fx-layout-align-end">
                        <button mat-button type="button" (click)="cancelEditingPrice($event)">Cancel</button>
                        <button mat-button color="primary" (click)="doneEditingPrice($event)">Add</button>
                      </div>
                    </div>
                  }
                  @if (editingIndex() === null) {
                    @if (!price.value.productPriceId) {
                      <button
                        type="button"
                        class="close-btn"
                        matTooltip="Remove this price"
                        mat-icon-button
                        (click)="removePrice($event, $index)"
                      >
                        <mat-icon svgIcon="close"></mat-icon>
                      </button>
                    } @else {
                      <button
                        type="button"
                        class="archive-btn"
                        matTooltip="Archive this price"
                        mat-icon-button
                        (click)="archivePrice($event, $index)"
                      >
                        <mat-icon svgIcon="archive"></mat-icon>
                      </button>
                    }
                  }
                </div>
              </mat-list-item>
            </mat-list>
          }
        }
        @if (archivedPrices.length > 0) {
          <div class="price-list-header fx-layout-row fx-layout-align-start-center">
            <h3>Archived</h3>
          </div>
          @for (price of archivedPrices; track $index) {
            <mat-list role="list">
              <mat-list-item class="price-list-item" role="listitem">
                <div class="fx-layout-row fx-layout-align-start-center">
                  <div class="price-info fx-flex">
                    <ih-price-interval
                      [amount]="price.value.amount ?? 0"
                      [currency]="price.value.currency?.code"
                      [interval]="price.value.interval ?? RecurringInterval.Never"
                      [intervalCount]="price.value.intervalCount"
                      [taxBehavior]="price.value.taxBehavior!"
                    ></ih-price-interval>
                  </div>
                  <button
                    type="button"
                    class="restore-btn"
                    matTooltip="Restore this price"
                    mat-icon-button
                    (click)="restorePrice($event, $index)"
                  >
                    <mat-icon svgIcon="restore"></mat-icon>
                  </button>
                </div>
              </mat-list-item>
            </mat-list>
          }
        }
        <ih-channel-autocomplete [filter]="{ joinType: ChannelJoinType.Paid }"></ih-channel-autocomplete>
      </div>
    </form>
  </ng-container>
  <ng-container ih-footer>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button [inProgress]="updating()" type="submit" color="primary" form="productForm">
      {{ productForm.controls['productId'].value ? 'Save' : 'Create' }}
    </button>
  </ng-container>
</ih-basic-dialog>
