import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MediaCropImage } from '@ih/interfaces';
import { Observable } from 'rxjs';
import { ProductImageEditorComponent } from './product-image-editor.component';

@Injectable({
  providedIn: 'root'
})
export class ProductImageEditorDialogService {
  private dialog = inject(MatDialog);

  open(image: MediaCropImage): Observable<MediaCropImage> {
    return this.dialog
      .open(ProductImageEditorComponent, {
        data: image,
        maxWidth: undefined,
        panelClass: 'product-image-editor-dialog'
      })
      .afterClosed();
  }
}
