import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductListItem } from '@ih/interfaces';
import { Observable } from 'rxjs';
import { ProductEditDialogComponent } from './product-edit-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ProductEditDialogService {
  private dialog = inject(MatDialog);

  open<T>(product: ProductListItem<T>): Observable<ProductListItem<T>> {
    // check if the dialog is already open
    const existingDialog = this.dialog.getDialogById('product-edit-dialog');
    if (existingDialog) {
      return existingDialog.afterClosed();
    }

    return this.dialog
      .open(ProductEditDialogComponent, {
        id: 'product-edit-dialog',
        data: product,
        maxWidth: undefined,
        panelClass: 'product-edit-dialog'
      })
      .afterClosed();
  }
}
