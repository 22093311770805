<mat-dialog-content>
  <ih-image-editor
    [formControl]="image"
    [idealWidth]="500"
    [idealHeight]="500"
    placeholder-svg="image"
    type="product"
  ></ih-image-editor>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [disabled]="!image.value?.mediaId" [mat-dialog-close]="image.value">Apply</button>
</mat-dialog-actions>
