<mat-form-field class="channel-autocomplete-field" floatLabel="always" appearance="outline" subscriptSizing="dynamic">
  <mat-label>Channels</mat-label>
  <mat-chip-grid #reactiveChipGrid aria-label="Enter channel name to select">
    @for (channel of this.channels?.value ?? []; track channel.channelId) {
      <mat-chip-row (removed)="removeChannel(channel)">
        <div class="channel-wrapper fx-layout-row fx-layout-align-start-center">
          <ih-channel-icon [channel]="channel"></ih-channel-icon>
          {{ channel.name }}
        </div>
        <button matChipRemove aria-label="'remove reactive form' + keyword">
          <mat-icon svgIcon="close-circle"></mat-icon>
        </button>
      </mat-chip-row>
    }
  </mat-chip-grid>
  <input
    placeholder="Type a channel name..."
    [formControl]="query"
    [matChipInputFor]="reactiveChipGrid"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    @for (channel of filteredChannels(); track channel.channelId) {
      <mat-option [value]="channel">
        <div class="channel-wrapper fx-layout-row fx-layout-align-start-center">
          <ih-channel-icon [channel]="channel"></ih-channel-icon>
          {{ channel.name }}
        </div>
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
