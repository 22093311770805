import { ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlContainer, FormControl, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MAT_CHIPS_DEFAULT_OPTIONS, MatChipsDefaultOptions, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ChannelIconComponent } from '@ih/channel-icon';
import { Channel } from '@ih/interfaces';
import { ChannelService } from '@ih/services';
import { ngValueAccessorProvider } from '@ih/utilities';
import { map, withLatestFrom } from 'rxjs';

@Component({
  selector: 'ih-channel-autocomplete',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    ChannelIconComponent
  ],
  templateUrl: './channel-autocomplete.component.html',
  styleUrl: './channel-autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ngValueAccessorProvider(() => ChannelAutocompleteComponent),
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER]
      } as MatChipsDefaultOptions
    },
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ChannelAutocompleteComponent implements OnInit {
  private controlContainer = inject(ControlContainer) as FormGroupDirective;
  private channelService = inject(ChannelService);
  private destroyRef = inject(DestroyRef);

  filter = input<Partial<Channel>>({});

  channels?: FormControl<Channel[]>;

  private allChannels = this.channelService.channels$.pipe(map((channels) => Object.values(channels)));

  query = new FormControl('');

  filteredChannels = signal<Channel[]>([]);

  ngOnInit(): void {
    this.channels = this.controlContainer.form.get('channels') as FormControl<Channel[]>;

    this.query.valueChanges
      .pipe(withLatestFrom(this.allChannels), takeUntilDestroyed(this.destroyRef))
      .subscribe(([query, channels]) => {
        if (!channels) {
          this.filteredChannels.set([]);
        }

        if (!query) {
          this.filteredChannels.set(channels);
        }

        // if the query is not a string
        if (typeof query !== 'string') {
          return;
        }

        query = query.toLowerCase();

        const filter = this.filter();

        this.filteredChannels.set(
          channels.filter((channel) => {
            if (filter) {
              for (const key in filter) {
                if (filter[key as keyof Channel] !== channel[key as keyof Channel]) {
                  return false;
                }
              }
            }

            return channel.name.toLowerCase().includes(query);
          })
        );
      });
  }

  selected(event: MatAutocompleteSelectedEvent) {
    this.channels!.patchValue([...this.channels!.value, event.option.value]);
    this.query.setValue('');
  }

  removeChannel(channel: Channel) {
    this.channels!.patchValue(this.channels!.value.filter((c) => c.channelId !== channel.channelId));
  }
}
