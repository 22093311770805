import { AsyncPipe, CurrencyPipe, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BasicDialogComponent } from '@ih/basic-dialog';
import { ChannelAutocompleteComponent } from '@ih/channel-autocomplete';
import { InProgressDirective } from '@ih/directives';
import { ChannelJoinType, RecurringInterval, TaxBehavior } from '@ih/enums';
import { ImageComponent } from '@ih/image';
import { InfoPanelComponent } from '@ih/info-panel';
import { AppConfig, Channel, MediaCropImage, Price, PriceForm, Product, ProductListItem } from '@ih/interfaces';
import { PriceIntervalComponent } from '@ih/price-interval';
import { AuthService, ChannelService, ConfigService, ProductService } from '@ih/services';
import { throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { PriceEditorComponent } from '../price-editor/price-editor.component';
import { ProductImageEditorDialogService } from '../product-image-editor/product-image-editor-dialog.service';

@Component({
  selector: 'ih-product-edit-dialog',
  standalone: true,
  imports: [
    AsyncPipe,
    CurrencyPipe,
    KeyValuePipe,
    NgFor,
    NgIf,
    RouterModule,

    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,

    BasicDialogComponent,
    ChannelAutocompleteComponent,
    ImageComponent,
    InfoPanelComponent,
    InProgressDirective,
    PriceIntervalComponent,

    PriceEditorComponent
  ],
  templateUrl: './product-edit-dialog.component.html',
  styleUrls: ['./product-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductEditDialogComponent {
  private auth = inject(AuthService);
  private config = inject(ConfigService<AppConfig>) as ConfigService<AppConfig>;
  private matDialogRef = inject(MatDialogRef<ProductEditDialogComponent, Product>);
  private data = inject(MAT_DIALOG_DATA) as ProductListItem<MediaCropImage>;
  private productService = inject(ProductService);
  private productImageEditorDialog = inject(ProductImageEditorDialogService);
  private channelService = inject(ChannelService);
  private fb = inject(FormBuilder);

  updating = signal(false);

  productForm = new FormGroup({
    productId: new FormControl<number | null>(this.data?.productId ?? null),
    stripeProductId: new FormControl<string | null>(this.data?.stripeProductId ?? null),
    name: new FormControl<string>(this.data?.name, Validators.required),
    description: new FormControl(this.data?.description, [Validators.required, Validators.maxLength(2000)]),
    features: new FormControl<string[]>(this.data?.features ?? []),
    image: new FormControl<MediaCropImage | null>(this.data?.image ?? null),
    quantity: new FormControl<number | undefined>(this.data?.quantity),
    limit: new FormControl<number | undefined>(this.data?.limit),
    stripeTaxCodeId: new FormControl<string | null>(this.data?.stripeTaxCodeId),
    prices: this.fb.array((this.data?.prices ?? []).map((price) => this.getPriceFormGroup(price))),
    channels: new FormControl<Channel[] | null>([])
  });

  get prices(): FormGroup<PriceForm>[] {
    return this.productForm.controls['prices'].controls.filter(
      (priceForm: FormGroup<PriceForm>) => !priceForm.value.archived
    ) as FormGroup<PriceForm>[];
  }

  get archivedPrices(): FormGroup<PriceForm>[] {
    return this.productForm.controls['prices'].controls.filter((priceForm: FormGroup) => priceForm.value.archived);
  }

  intervals = Object.entries(RecurringInterval).map(([key, value]) => ({ key, value }));

  editingIndex = signal<number | null>(null);

  currency = toSignal(this.config.config$.pipe(map((config) => config.currency)));

  currentUser = toSignal(this.auth.currentUser$);

  RecurringInterval = RecurringInterval;

  ChannelJoinType = ChannelJoinType;

  imageInfo$ = this.productForm.controls['image'].valueChanges.pipe(
    startWith(this.productForm.controls['image'].value),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    map((image) => {
      if (!image) {
        return null;
      }

      return {
        url: image.url,
        cropData: image.cropData
      };
    })
  );

  constructor() {
    this.channelService.channels$.subscribe((channels) => {
      this.productForm.controls['channels'].setValue(
        this.data.channelIds?.map((channelId) => channels[channelId]).filter((c) => c)
      );
    });
  }

  getPriceFormGroup(price: Price): FormGroup<PriceForm> {
    return new FormGroup<PriceForm>({
      productPriceId: this.fb.control(price.productPriceId),
      stripePriceId: this.fb.control(price.stripePriceId),
      amount: this.fb.control(price.amount, [Validators.required, Validators.min(1), Validators.max(999999)]),
      currency: this.fb.control(price.currency, Validators.required),
      interval: this.fb.control(price.interval ?? RecurringInterval.Month),
      intervalCount: this.fb.control(price.intervalCount),
      taxBehavior: this.fb.control(price.taxBehavior ?? TaxBehavior.Exclusive),
      archived: this.fb.control(price.archived ?? false),
      wasAdded: this.fb.control(false)
    });
  }

  showChangeImage(): void {
    const image = this.productForm.controls['image'].value!;
    if (!image) {
      return;
    }

    this.productImageEditorDialog.open(image).subscribe((image: MediaCropImage) => {
      if (image) {
        this.productForm.patchValue({ image });
      }
    });
  }

  addPrice(): void {
    if (this.editingIndex() !== null) {
      return;
    }

    const priceGroup: FormGroup<PriceForm> = this.getPriceFormGroup({
      amount: 0,
      currency: this.currency()!,
      interval: RecurringInterval.Month,
      intervalCount: 1,
      taxBehavior: TaxBehavior.Exclusive
    });
    this.productForm.controls['prices'].push(priceGroup);

    // find the index of the newly added price
    const editIndex = this.prices.findIndex((priceForm: FormGroup<PriceForm>) => priceForm === priceGroup);

    this.editingIndex.set(editIndex);

    setTimeout(() => {
      const priceInput = document.querySelector('.price-input:last-of-type') as HTMLInputElement;
      priceInput.focus();
    });
  }

  archivePrice(event: Event, index: number): void {
    event.stopPropagation();

    // Find price index
    const removeIndex = this.productForm.controls['prices'].controls.findIndex(
      (priceForm: FormGroup<PriceForm>) => priceForm === this.prices[index]
    );
    // set the price as archived
    this.productForm.controls['prices'].at(removeIndex).patchValue({ archived: true });
  }

  restorePrice(event: Event, index: number): void {
    event.stopPropagation();

    // Find price index
    const removeIndex = this.productForm.controls['prices'].controls.findIndex(
      (priceForm: FormGroup<PriceForm>) => priceForm === this.archivedPrices[index]
    );
    // set the price as not archived
    this.productForm.controls['prices'].at(removeIndex).patchValue({ archived: false });
  }

  removePrice(event:Event, index: number): void {
    event.stopPropagation();

    const removeIndex = this.productForm.controls['prices'].controls.findIndex(
      (priceForm: FormGroup<PriceForm>) => priceForm === this.prices[index]
    );
    this.productForm.controls['prices'].removeAt(removeIndex);
  }

  cancelEditingPrice(event: Event): void {
    event.stopPropagation();

    if (this.editingIndex() === null) {
      return;
    }

    if (!this.prices[this.editingIndex()!].value.wasAdded) {
      this.removePrice(event, this.editingIndex()!);
    }

    this.editingIndex.set(null);
  }

  doneEditingPrice(event: Event): void {
    event.stopPropagation();

    if (this.editingIndex() === null) {
      return;
    }

    if (!this.prices[this.editingIndex()!].valid) {
      return;
    }

    this.prices[this.editingIndex()!].controls['wasAdded']!.setValue(true);

    this.editingIndex.set(null);
  }

  editItem(index: number): void {
    // only allow editing if the priceId is not set
    if (this.prices[index].value.productPriceId) {
      return;
    }

    this.editingIndex.set(index);
  }

  save(): void {
    if (!this.productForm.valid || this.editingIndex() !== null) {
      return;
    }

    this.updating.set(true);

    // convert dollar amounts to cents
    const payload = this.productForm.value as Product<MediaCropImage>;
    payload.prices = payload.prices.map((price) => {
      return {
        ...price,
        amount: price.amount * 100
      };
    });

    // convert channels to channelIds
    payload.channelIds = (payload as any).channels?.map((channel: Channel) => channel.channelId) ?? [];

    this.productService
      .updateProduct$(payload)
      .pipe(
        tap({
          error: () => this.updating.set(false),
          next: (updatedProduct) => {
            this.updating.set(false);
            this.matDialogRef.close(updatedProduct);
          }
        }),
        catchError(() => {
          this.updating.set(false);
          return throwError(() => new Error('Failed to update product'));
        })
      )
      .subscribe();
  }
}
